<template>
  <!-- layout-container lesson 15 -->
  <div class="lesson47-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageA />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 白板部分 -->
      <WhiteBoard :canvasHeight="cvHeight" :canvasWidth="cvWidth" />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
import SyllabusPageA from "./3_SyllabusPage_A.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import OpeningSongPage from "./4_OpeningSongPage.vue";
import IntroductionPage from "./5_Introduction.vue";
import StoryPageOne from "./6_StoryPageOne.vue";
import RecognitionPage_ai from "./7_RecognitionPage_ai.vue";
import RecognitionPage_ei from "./8_RecognitionPage_ei.vue";
import RecognitionPage_ui from "./9_RecognitionPage_ui.vue";
import StoryPageTwo from "./11_StoryPageTwo.vue";
import PracticePageTwo from "./10_PracticePageTwo.vue";
import RecognitionPage_ao from "./12_RecognitionPage_ao.vue";
import RecognitionPage_ou from "./13_RecognitionPage_ou.vue";
import RecognitionPage_iu from "./14_RecognitionPage_iu.vue";
import BasketballGame from "./15_BasketballGame.vue";
import StoryPageThree from "./16_StoryPageThree.vue";
import RecognitionPage_ie from "./17_RecognitionPage_ie.vue";
import RecognitionPage_ue from "./18_RecognitionPage_ue.vue";
import RecognitionPage_er from "./19_RecognitionPage_er.vue";
import PracticeGameOne from "./20.PracticeGameOne.vue";
import PuzzleGame from "./21_PuzzleGame.vue";
import TigetMachineGame from "./22_TigerMachineGame.vue";
import RobotJumpGame from "./22_RobotJumpGame.vue";
import PracticePageTow from "./23.PracticeGameTwo.vue";
import WritingPage from "./24_WritingPage.vue";
import WritingPage_1 from "./24_WritingPage_1.vue";

import PracticePageFour from "./25_PracticePageFour.vue";
import PinyinSummary from "./26_PinyinSummary.vue";
import RankMapPageTwo from "./27_RankMapPageTwo.vue";
import ByePage from "./28_ByePage.vue";
export default {
  name: "Lesson47",
  mixins: [lessonMixin],
  components: {
    SyllabusPageA,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    OpeningSongPage,
    IntroductionPage,
    StoryPageOne,
    RecognitionPage_ai,
    RecognitionPage_ei,
    RecognitionPage_ui,
    StoryPageTwo,
    PracticePageTwo,
    RecognitionPage_ao,
    RecognitionPage_ou,
    RecognitionPage_iu,
    BasketballGame,
    StoryPageThree,
    RecognitionPage_ie,
    RecognitionPage_ue,
    RecognitionPage_er,
    PracticeGameOne,
    PuzzleGame,
    TigetMachineGame,
    RobotJumpGame,
    PracticePageTow,
    WritingPage,
    WritingPage_1,
    PracticePageFour,
    PinyinSummary,
    RankMapPageTwo,
    ByePage,
  },
  data() {
    return {
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        OpeningSongPage,
        IntroductionPage,
        StoryPageOne,
        RecognitionPage_ai,
        RecognitionPage_ei,
        RecognitionPage_ui,
        PracticePageTwo,
        StoryPageTwo,
        RecognitionPage_ao,
        RecognitionPage_ou,
        RecognitionPage_iu,
        BasketballGame,
        StoryPageThree,
        RecognitionPage_ie,
        RecognitionPage_ue,
        RecognitionPage_er,
        PracticeGameOne,
        PuzzleGame,
        TigetMachineGame,
        RobotJumpGame,
        PracticePageTow,
        WritingPage,
        WritingPage_1,
        PracticePageFour,
        PinyinSummary,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson47-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
</style>
