<template>
  <div class="game-container">
    <PinyinPuzzleGame :optionList="optionList" :answerList="answerList" :menuItems="8" :menuSize="`small`"/>
  </div>
</template>
<script>
import PinyinPuzzleGame from "@/components/Course/GamePage/PinyinPuzzleGame";

export default {
  components: {
    PinyinPuzzleGame,
  },
  data() {
    return {
      optionList: [
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/hudie-ie-red.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/bei-ei-red.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/koushui-ai.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/xuehua-ue-red.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/koushui-ui-red.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/hudie-ie-red.svg"),
            showObj: true,
          },
        ],
      
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/nver-er-red.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/nver-ai.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/nver-er-2.svg"),
            showObj: true,
          },
          
        ],
         [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/niunai-niu-iu-red.svg"), 
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/hudie-ie-red.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/beibao-bao-ao-red.svg"),
            showObj: true,
          },
        ],
         [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/nver-ai.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/bei-ei-red.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/xuehua-ue-red.svg"),
            showObj: true,
          },
        ],
         [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/koushui-sh-ou-red.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/beibao-bao-ao-red.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/nver-ai.svg"),
            showObj: true,
          },
        ],
         [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/koushui-ui-red.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/hudie-ie-red.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/koushui-sh-ou-red.svg"),
            showObj: true,
          },
        ],
         [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/beibao-bei-ei-red.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/nver-ai.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/hudie-ie-red.svg"),
            showObj: true,
          },
         ],
         [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/beibao-bao-ao-red.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/nver-ai.svg"),
            showObj: true,
          },
          // {
          //   id: 3,
          //   image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/hudie-ie-red.svg"),
          //   showObj: true,
          // },
        ],
      ],

      answerList: [
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/hudie.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/d-white.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/hudie-ie-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/puzzle-character-1.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/xuehua.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/x-white.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/xuehua-ue-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/puzzle-character-2.svg"),
        },
        
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/nv'er.mp3"),
          isCorrect: false,
          // firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/white-r.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/nver-er-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/puzzle-character-3.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/niunai.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/niunai-niu-white.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/niunai-niu-iu-correct.svg"), 
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/puzzle-character-4.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/niunai.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/n-white.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/niunai-nai-ai-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/puzzle-character-5.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/koushui.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/k-white.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/koushui-ou-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/puzzle-character-6.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/koushui.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/sh-white.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/koushui-ui-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/puzzle-character-7.svg"),
        },
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/4-Pinyin-words/beibao.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/b-white.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/beibao-bei-ei-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/puzzle-character-8.svg"),
        },
        // {
        //   id: 1,
        //   audioUrl: require("@/assets/audio/a-zh/w/wu3.mp3"),
        //   isCorrect: false,
        //   firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/b-white.svg"),
        //   secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/beibao-bao-ao-correct.svg"),
        //   fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-47/puzzle-character-9.svg"),
        // },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.game-container {
  width: 100%;
  height: 100%;
}
</style>
