<template>
  <div class="write-page-heng-container">
    <WritePinyinPage
      :pinyinImgList="pinyinImgList"
      :pinyinList="pinyinList"
      :strokeTimesList="strokeTimesList"
      :buttonList="buttonList"
    />
  </div>
</template>
<script>
import WritePinyinPage from "@/components/Course/CoursePage/WritePinyinPage";

export default {
  data() {
    return {
      pinyinList: ["ou", "iu", "ie","üe", "er"], //ü
      strokeTimesList: [3, 4, 3, 5, 3],
      pinyinImgList:[
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-06/pinyin-ou-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-06/chapter-06-ou.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-06/pinyin-iu-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-06/chapter-06-iu.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-06/pinyin-ie-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-06/chapter-06-ie.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-06/pinyin-ve-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-06/chapter-06-üe.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-06/pinyin-er-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-06/chapter-06-er.gif'),
        },
        
        
      ],
      buttonList: [
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-ou.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-iu.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-ie.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-ve.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-er.svg"),
          startNumberRange: 5,
          endNumberRange: 5,
        },
        
      ],
    };
  },
  components: {
    WritePinyinPage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
