<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay"
    :lessonNum="11" :layout="`small`"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'yellowColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          english: "ai",
          englishClass:"font-english-medium",
          englishFontFamily:"SourceHanSans",
          longtext: true,
          image: require(`@/assets/img/14-Pinyin/chapter-06/pinyin-ai.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "three",
          isShowImg: "true",
          currentNum: 2,
          english: "ei",
          englishClass:"font-english-medium",
          englishFontFamily:"SourceHanSans",
          longtext: true,
          image: require(`@/assets/img/14-Pinyin/chapter-06/pinyin-ei.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "five",
          isShowImg: "true",
          currentNum: 3,
          english: "ui",
          englishClass:"font-english-medium",
          englishFontFamily:"SourceHanSans",
          longtext: true,
          image: require(`@/assets/img/14-Pinyin/chapter-06/pinyin-ui.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 4,
          english: "ao",
          englishClass:"font-english-medium",
          englishFontFamily:"SourceHanSans",
          longtext: true,
          image: require(`@/assets/img/14-Pinyin/chapter-06/pinyin-ao.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 5,
          english: "ou",
          englishClass:"font-english-medium",
          englishFontFamily:"SourceHanSans",
          longtext: true,
          image: require(`@/assets/img/14-Pinyin/chapter-06/pinyin-ou.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 6,
          english: "iu",
          englishClass:"font-english-medium",
          englishFontFamily:"SourceHanSans",
          longtext: true,
          image: require(`@/assets/img/14-Pinyin/chapter-06/pinyin-iu.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 7,
          english: "ie",
          englishClass:"font-english-medium",
          englishFontFamily:"SourceHanSans",
          longtext: true,
          image: require(`@/assets/img/14-Pinyin/chapter-06/pinyin-ie.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 8,
          english: "üe",
          englishClass:"font-english-medium",
          englishFontFamily:"SourceHanSans",
          longtext: true,
          image: require(`@/assets/img/14-Pinyin/chapter-06/pinyin-ve.svg`),
        },
        {
          bgColor: 'yellowColor',
          order: "two",
          isShowImg: "true",
          currentNum: 9,
          english: "er",
          englishClass:"font-english-medium",
          englishFontFamily:"SourceHanSans",
          longtext: true,
          image: require(`@/assets/img/14-Pinyin/chapter-06/pinyin-er.svg`),
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
