<template>
  <div class="write-page-heng-container">
    <WritePinyinPage
      :pinyinImgList="pinyinImgList"
      :pinyinList="pinyinList"
      :strokeTimesList="strokeTimesList"
      :buttonList="buttonList"
    />
  </div>
</template>
<script>
import WritePinyinPage from "@/components/Course/CoursePage/WritePinyinPage";

export default {
  data() {
    return {
      pinyinList: ["ai", "ei", "ui","ao"], //ü
      strokeTimesList: [4, 3, 4,3],
      pinyinImgList:[
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-06/pinyin-ai-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-06/chapter-06-ai.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-06/pinyin-ei-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-06/chapter-06-ei.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-06/pinyin-ui-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-06/chapter-06-ui.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-06/pinyin-ao-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-06/chapter-06-ao.gif'),
        },
        
        
      ],
      buttonList: [
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-ai.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-ei.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-ui.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-ao.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
        
      ],
    };
  },
  components: {
    WritePinyinPage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
