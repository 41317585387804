<template>
  <div class="game-container">
    <PinyinMoveGame
      :bgImg="bgImg"
      :studentImg="studentImg"
      :lessonNum="lessonNum"
      :durationArr="durationArr"
    />
  </div>
</template>

<script>
import PinyinMoveGame from "@/components/Course/GamePage/PinyinMoveGame";
export default {
  data() {
    return {
      lessonNum: 15,
      bgImg: require("@/assets/img/16-GAMES/G12-map-pinyin/map-pinyin-chapter-6.svg"),
      // frontImg: require("@/assets/img/16-GAMES/G12-map/lesson-07-map-2.svg"),
      studentImg: require("@/assets/img/16-GAMES/G12-map/student.svg"),
      durationArr: [0, 0.1, 0.2, 0.35, 0.5, 0.6, 0.7, 0.8, 1],
    };
  },
  components: {
    PinyinMoveGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
