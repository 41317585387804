<template>
  <div class="game-container">
    <RobotJumpGame
      :bgImg="bgImg"
      :successBgImg="successBgImg"
      :studentImg="studentImg"
      :lessonNum="lessonNum"
      :durationArr="durationArr"
      :answerList="answerList"
      :audioList="audioList"
    />
  </div>
</template>

<script>
import RobotJumpGame from "@/components/Course/GamePage/RobotJumpGame";
export default {
  data() {
    return {
      lessonNum: 7,
      answerList: [0, 0, 1, 1, 1, 0, 0],
      bgImg: require("@/assets/img/16-GAMES/G56-map-sea/chapter-06/sea-background-0.svg"),
      studentImg: require("@/assets/img/16-GAMES/G56-map-sea/sea-robot.svg"),
      durationArr: [0, 0.15, 0.25, 0.3, 0.5, 0.7, 0.9, 1],
      audioList: [
        require("@/assets/audio/L1/2-Pinyin/ai.mp3"),
        require("@/assets/audio/L1/2-Pinyin/ao.mp3"),
        require("@/assets/audio/L1/3-Pinyin-map/l-ei4.mp3"),
        require("@/assets/audio/L1/3-Pinyin-map/d-ui4.mp3"),
        require("@/assets/audio/L1/3-Pinyin-map/sh-ao3.mp3"),
        require("@/assets/audio/L1/3-Pinyin-syllables/rao4.mp3"),
        require("@/assets/audio/L1/3-Pinyin-syllables/nv4.mp3"),
      ],
    };
  },
  computed: {
    successBgImg() {
      let imgArr = [];
      for (let i = 0; i <= 8; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G56-map-sea/chapter-06/sea-background-${i}.svg`)
        );
      }
      return imgArr;
    },
  },
  components: {
    RobotJumpGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>










