<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-47.mp3"),
      },
      timeStampList: [10.3, 13.4, 20.6, 23.4, 33.3, 36.2, 40, 41.3, 50],
      singList: {
        lyricsArr: [
          {
            id: 1,
            chineseWords: "ai, ei,ui",
          },
          {
            id: 2,
            chineseWords: "ài-wǒ ài nǐ, mèimei（妹妹）",
          },
          {
            id: 3,
            chineseWords: "ao,ou,iu",
          },
          {
            id: 4,
            chineseWords: "m, g, n, māo（猫）,gǒu（狗）, niú（牛）",
          },
          {
            id: 5,
            chineseWords: "ie, üe, er",
          },
          {
            id: 6,
            chineseWords: "Wǒ zài xuéxiào.（我在学校。）",
          },
          {
            id: 7,
            chineseWords: "Xièxie nǐ.（谢谢你。）",
          },
          {
            id: 8,
            chineseWords: "ai, ei, ui, ao, ou, iu, ie, üe, er",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
